import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const MobileStaticAnalysisPageTemplate = ({
  seo,
  hero,
  contactus,
  wcisection,
  androidsection,
  iossection,
  windowssection,
  blackberrysection,
  wgasection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} blogservice={true} />
      <HeroOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
            </div>
            <div className="column is-4">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${wcisection.sectionbg}` }}>
        <div className="container">
          <div style={{ margin: "2rem 0" }}>
            <Title title={wcisection.title} color={wcisection.textcolor} />
            <Title title={wcisection.secondtitle} color={wcisection.textcolor} />
            <SubTitle title={wcisection.subheader} color={wcisection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={wcisection.descriptions} color={wcisection.textcolor} margin="1rem 0 0" />
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${androidsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "4rem" }}>
            <Title title={androidsection.title} color={androidsection.textcolor} />
            <Title title={androidsection.secondtitle} color={androidsection.textcolor} />
            <SubTitle title={androidsection.subheader1} color={androidsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={androidsection.descriptions1} color={androidsection.textcolor} margin="1rem 0" />
            <SubTitle title={androidsection.subheader2} color={androidsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={androidsection.descriptions2} color={androidsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300, height: 100 }}>
                <PreviewCompatibleImage imageInfo={androidsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Descriptions descriptions={androidsection.sidedescriptions} color={androidsection.textcolor} margin="1rem 0" />
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ margin: "4rem 0 2rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <Title title={iossection.title} />
              <Title title={iossection.secondtitle} />
              <SubTitle title={iossection.subheader1} margin="1.5rem 0" />
              <Descriptions descriptions={iossection.sidedescriptions} margin="1rem 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300, height: 100 }}>
                <PreviewCompatibleImage imageInfo={iossection.image} />
              </div>
            </div>
          </div>
          <SubTitle title={iossection.subheader2} margin="1.5rem 0" />
          <Descriptions descriptions={iossection.descriptions} margin="1rem 0" />
        </div>
      </section>
      <section className="section" style={{ background: `${windowssection.sectionbg}` }}>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300 }}>
                <PreviewCompatibleImage imageInfo={windowssection.image} />
              </div>
            </div>
            <div className="column is-8" style={{ margin: "2rem 0" }}>
              <Title title={windowssection.title} color={windowssection.textcolor} />
              <Title title={windowssection.secondtitle} color={windowssection.textcolor} />
              <SubTitle title={windowssection.subheader} color={windowssection.textcolor} margin="1.5rem 0" />
              <Descriptions descriptions={windowssection.sidedescriptions} color={wcisection.textcolor} margin="1rem 0 0" />
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8" style={{ margin: "2rem 0" }}>
              <Title title={blackberrysection.title} />
              <Title title={blackberrysection.secondtitle} />
              <SubTitle title={blackberrysection.subheader} margin="1.5rem 0" />
              <Descriptions descriptions={blackberrysection.sidedescriptions} margin="1rem 0 0" />
            </div>
            <div className="column is-4">
              <div style={{ maxWidth: 300, marginTop: 50 }}>
                <PreviewCompatibleImage imageInfo={blackberrysection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${wgasection.sectionbg}` }}>
        <div className="container">
          <div style={{ margin: "2rem 0" }}>
            <Title title={wgasection.title} color={wgasection.textcolor} />
            <Title title={wgasection.secondtitle} color={wgasection.textcolor} />
            <SubTitle title={wgasection.subheader} color={wgasection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={wgasection.descriptions} color={wgasection.textcolor} margin="1rem 0 0" />
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

MobileStaticAnalysisPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  wcisection: PropTypes.object,
  androidsection: PropTypes.object,
  iossection: PropTypes.object,
  windowssection: PropTypes.object,
  blackberrysection: PropTypes.object,
  wgasection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const MobileStaticAnalysisPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <MobileStaticAnalysisPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        wcisection={frontmatter.wcisection}
        androidsection={frontmatter.androidsection}
        iossection={frontmatter.iossection}
        windowssection={frontmatter.windowssection}
        blackberrysection={frontmatter.blackberrysection}
        wgasection={frontmatter.wgasection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

MobileStaticAnalysisPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default MobileStaticAnalysisPage

export const pageQuery = graphql`
  query MobileStaticAnalysisPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "static-analysis" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          buttontxt
          buttonlink
        }
        wcisection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        androidsection {
          sectionbg
          textcolor
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        iossection {
          title
          subheader1
          sidedescriptions
          subheader2
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        windowssection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        blackberrysection {
          title
          secondtitle
          subheader
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        wgasection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
